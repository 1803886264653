import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { useGetFeaturesQuery } from "store/services/featuresApi";
import FeaturesView from "views/Features";

function Features() {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://s3.ap-south-1.amazonaws.com/xane-data/projects/tdc-iod/mock/features.json"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();

        setFeatures(jsonData.features);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (features.length > 0) return <FeaturesView features={features} />;
  return <Loader />;
}

export default Features;
