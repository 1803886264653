import React from "react";
import { Box } from "@mui/material";
import style from "./style.css";

function Loader() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner" />
    </Box>
  );
}
export default Loader;
