import { createSlice } from "@reduxjs/toolkit";
import { api } from "config/apiHandler";
import cookies from "services/browserStorage/cookies";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: cookies.getAuth().user || null,
    token: cookies.getAuth().token || null,
  },
  reducers: {},
  // extraReducers: (builder) => {
  //   builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
  //     state.user = payload.user;
  //     state.token = payload.session.token;
  //   });
  //   builder.addMatcher(api.endpoints.logout.matchFulfilled, (state, { payload }) => {
  //     state.user = payload.user;
  //     state.token = null;
  //   });
  // },
});

// Action creators are generated for each case reducer function
export const { _ } = authSlice.actions;

export default authSlice.reducer;
