import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import store from "store/store";
import App from "App";
import { Provider } from "react-redux";

import "./style/utils.css";
import "./style/customStyle.css";
import "./style/BackButton.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
