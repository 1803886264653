import { ChevronLeft } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

function NavigateToBack({ children }) {
  const Navigate = useNavigate();
  return (
    <IconButton aria-label="delete" size="large" onClick={() => Navigate(-1)}>
      {children}
    </IconButton>
  );
}

NavigateToBack.defaultProps = {
  children: (
    <ChevronLeft
      fontSize="inherit"
      sx={{ color: "#D3D3D3", fontWeight: "bold", fontSize: "2rem" }}
    />
  ),
};

NavigateToBack.propTypes = {
  children: PropTypes.node,
};

export default NavigateToBack;
