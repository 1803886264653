import { Buffer } from "buffer";
import convertToBase64 from "services/exports/convertToBase64";
import uploadToS3 from "services/exports/uploadToS3";
import uniqueId from "services/utils/generateUniqueId";

const uploadImageAndGetURL = async (image, filePath = "assets") => {
  const imageBuff = image;
  //   const fileName = image.name || "unknown";
  const fileType = "image/jpeg";
  const metaData = {
    Body: await Buffer.from(imageBuff.replace(/^data:image\/\w+;base64,/, ""), "base64"),
    Key: filePath,
    ContentType: fileType === "image/jpeg" ? "image/jpeg" : "image/png",
  };
  const file = await uploadToS3(metaData);
  return file.Location;
};

export default uploadImageAndGetURL;
