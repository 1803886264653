/* eslint-disable react/display-name */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ReactCrop from "react-image-crop";
import PropTypes from "prop-types";
import { useEffect } from "react";
import {
  AppBar,
  Box,
  CardMedia,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import "react-image-crop/dist/ReactCrop.css";
import BackButton from "components/BackButton";
import CustomButton from "components/CustomButton/CustomButton";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function Preview({ imageSrc, handleCloseImagePreview, handleProceed, partName }) {
  const [open, setOpen] = React.useState(false);
  const [crop, setCrop] = React.useState(null);

  useEffect(() => {
    console.log(crop);
  }, [crop]);

  useEffect(() => {
    if (imageSrc) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [imageSrc]);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  const handleClose = () => {
    handleCloseImagePreview();
    setOpen(false);
  };
  // const handleSubmit = () => {
  //   setOpen(false);
  //   handleProceed({ crop });
  // };
  const handleSubmit = () => {
    setOpen(false);
    const restrictedCrop = { ...crop }; // Make a copy of the crop object
    // Restrict crop dimensions to not exceed image dimensions
    if (restrictedCrop.width > imageSrc.width) {
      restrictedCrop.width = imageSrc.width;
    }
    if (restrictedCrop.height > imageSrc.height) {
      restrictedCrop.height = imageSrc.height;
    }
    handleProceed({ crop: restrictedCrop });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            background: "#162936",
          },
        }}
      >
        <Box
          sx={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#D3D3D3",
              textShadow: `rgb(211, 211, 211,0.4) 3px 3px 3px `,
            }}
          >
            {partName.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
          </Typography>
          <Box sx={{ padding: "10px" }}>
            <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
              <CardMedia
                component="img"
                image={imageSrc}
                alt="click-image"
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </ReactCrop>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "16px",
              padding: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              fullWidth
              sx={{
                height: "50px",
                background: "#eaeaea",
                color: "#162936",
                "&:hover": {
                  background: "#162936",
                  color: "#eaeaea",
                },
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={crop === null}
              fullWidth
              sx={{
                height: "50px",
                background: "#eaeaea",
                color: "#162936",
                "&:hover": {
                  background: "#162936",
                  color: "#eaeaea",
                },
              }}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
Preview.propTypes = {
  handleProceed: PropTypes.func.isRequired,
  imageSrc: PropTypes.any.isRequired,
};
