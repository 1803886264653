import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function Button() {
  return (
    <div className="container">
      <div className="toggle">
        <input type="checkbox" />
        <span className="button" />
        <span className="label">
          <NavigateNextIcon fontSize="md" />
        </span>
      </div>
    </div>
  );
}

export default Button;
