// Convert to YOLO format

function convertToYOLOv8Labels(box, image) {
  const x_center = (box.x + box.width / 2) / image.width;
  const y_center = (box.y + box.height / 2) / image.height;
  const width = box.width / image.width;
  const height = box.height / image.height;

  const yoloLabels = {
    label_id: box.label_id,
    x_center: x_center.toFixed(6),
    y_center: y_center.toFixed(6),
    width: width.toFixed(6),
    height: height.toFixed(6),
  };

  return `${yoloLabels.label_id} ${yoloLabels.x_center} ${yoloLabels.y_center} ${yoloLabels.width} ${yoloLabels.height}`;
}
export default convertToYOLOv8Labels;

// // Mock / Test
// const jsonData = {
//   label_id: 1,
//   x_bb: 100,
//   y_bb: 50,
//   w_bb: 200,
//   h_bb: 150,
//   w_image: 800,
//   h_image: 600,
// };

// const yoloLabels = convertToYOLOv8Labels(jsonData);
// const yoloLabels_txt = `${yoloLabels.label_id} ${yoloLabels.x_center} ${yoloLabels.y_center} ${yoloLabels.width} ${yoloLabels.height}`;
