// Capture.js
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import TestShot from "./TestShot";
import LiveShot from "./LiveShot";

function Capture() {
  const [captureType, setCaptureType] = useState("test");
  const [showTestCamera, setShowTestCamera] = useState(false);
  const [showLiveCamera, setShowLiveCamera] = useState(false);
  const [coors, setCoors] = useState({ x: 0, y: 0, height: 0, width: 0, unit: "px" });
  const [image, setImage] = useState({ height: 0, width: 0 });
  const { code } = useParams();

  const handleCaptureTypeChange = (event) => {
    setCaptureType(event.target.value);
  };

  const handleCoors = (crop, imageData) => {
    setCoors(crop.crop);
    setImage(imageData);
    setShowTestCamera(false);
  };
  const handleCaptureImage = () => {
    if (captureType === "test") {
      // Handle capturing test image
      setShowTestCamera(true);
      setShowLiveCamera(false);
    } else {
      // Handle capturing live image
      setShowTestCamera(false);
      setShowLiveCamera(true);
    }
  };

  return (
    <Box>
      {coors.x === 0 && coors.y === 0 && coors.height === 0 && coors.width === 0 ? (
        <>
          <Box
            sx={{
              background: "transparent",
              marginTop: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                color: "#D3D3D3",
                textShadow: `rgb(211, 211, 211,0.4) 3px 3px 3px `,
              }}
            >
              Test Image
            </Typography>
          </Box>
          <TestShot handleCoors={handleCoors} partName={code} />
        </>
      ) : (
        <>
          <Box
            sx={{
              background: "transparent",
              marginTop: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                color: "#D3D3D3",
                textShadow: `rgb(211, 211, 211,0.4) 3px 3px 3px `,
              }}
            >
              Live Image
            </Typography>
          </Box>
          <LiveShot coors={coors} image={image} partName={code} />
        </>
      )}
    </Box>
  );
}

export default Capture;
