import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Camera } from "react-camera-pro";

function Cam({ webcamRef, facingMode }) {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const videoConstraints = {
    facingMode: { exact: facingMode },
    // width: { ideal: "auto" },
    // height: { ideal: "auto" },
    // aspectRatio: detectPlatform() === "ios" ? 0.6666666667 : 1,
    aspectRatio: 1,
  };

  useEffect(() => {
    const video = document.querySelector("video");
    video?.setAttribute("playsinline", "");
    video?.setAttribute("webkit-playsinline", "");
    video?.setAttribute("autoplay", "");
    video?.removeAttribute("controls");
    video?.removeAttribute("loop");
    video?.removeAttribute("muted");
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {/* <Webcam
        mirrored={facingMode === "user"}
        audio={false}
        screenshotQuality={1}
        videoConstraints={videoConstraints}
        ref={webcamRef}
        screenshotFormat="image/png"
      /> */}
      <Camera
        ref={webcamRef}
        aspectRatio={4 / 5}
        numberOfCamerasCallback={setNumberOfCameras}
        facingMode="environment"
      />
    </Box>
  );
}

export default Cam;
