import { configureStore } from "@reduxjs/toolkit";
import { api } from "config/apiHandler";
import authSlice from "store/slices/authSlice";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authSlice,
  },

  middleware: (gDM) => gDM().concat(api.middleware),
});

export default store;
