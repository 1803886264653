import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBrowserFingerprint from "get-browser-fingerprint";

export const API_URL = "https://s3.ap-south-1.amazonaws.com/xane-data/projects/assembly-line";

export const getIdentity = async () => {
  const identifier = await getBrowserFingerprint({
    enableWebgl: true,
  });
  return identifier.toString();
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: async (headers, { endpoint, getState }) => {
      // headers.set("x-device-id", "cms.device");
      headers.set("x-key", process.env.REACT_APP_API_KEY);
      headers.set("x-platform", 1);
      headers.set("x-origin", 3);
      headers.set("x-version", 1);
      headers.set("Content-Type", "application/json");
      const { token } = getState().auth;
      const identifier = await getIdentity();
      headers.set("x-identifier", identifier);
      // headers.set("x-auth", "bu.test");
      headers.set("x-auth", token);
      if (endpoint.includes("login") && !endpoint.includes("logout")) {
        headers.delete("x-auth");
      }
      if (endpoint.includes("login") === false) {
        headers.delete("x-key");
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default api;
